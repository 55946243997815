import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 MilesRidge AI. All rights reserved.</p>
      <p>SSM Registration Number:202403230152</p>
    </footer>
  );
}

export default Footer;