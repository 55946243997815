import React from 'react';

function PrivacyPolicy() {
  return (
    <section className="privacy-policy">
      <h2>Privacy Policy</h2>
      <p><strong>Effective:</strong> 2024</p>
      <p>
        Thank you for choosing Milesridge AI Solutions ("we," "us," "our"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our services.
      </p>
      <h3>1. Information We Collect</h3>
      <h4>Personal Information You Provide</h4>
      <p>
        We may collect personal information that you voluntarily provide to us when you use our services, such as:
      </p>
      <ul>
        <li><strong>Contact Information:</strong> Name, email address, phone number.</li>
        <li><strong>Account Information:</strong> Username, password, and profile details.</li>
        <li><strong>Communications:</strong> Information you provide when communicating with us, such as questions or feedback.</li>
      </ul>
      <h4>Automatically Collected Information</h4>
      <p>
        When you interact with our services, we may automatically collect information such as:
      </p>
      <ul>
        <li><strong>Usage Data:</strong> Details about how you use our services, including pages visited, features used, and activity timestamps.</li>
        <li><strong>Device Information:</strong> Information about the device you use to access our services, including IP address, browser type, and operating system.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to collect information about your use of our website.</li>
      </ul>
      <h3>2. How We Use Your Information</h3>
      <p>We use your personal information for purposes such as:</p>
      <ul>
        <li><strong>Providing and Improving Our Services:</strong> To operate, maintain, and enhance our services.</li>
        <li><strong>Communication:</strong> To respond to inquiries, provide customer support, and send updates about our services.</li>
        <li><strong>Analytics:</strong> To analyze how users interact with our services and improve user experience.</li>
        <li><strong>Model Training and Improvement:</strong> With your consent, we may use data collected from your interactions to improve and train our AI models, helping us provide better, more personalized services. Any data used for training is anonymized to protect your identity.</li>
        <li><strong>Legal Compliance:</strong> To comply with applicable laws, regulations, and legal processes.</li>
      </ul>
      <h3>3. How We Share Your Information</h3>
      <p>We do not sell your personal information. We may share your information in the following ways:</p>
      <ul>
        <li><strong>Service Providers:</strong> We work with third-party companies that assist us in providing services, such as hosting, data analysis, and customer support.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid legal requests by public authorities.</li>
        <li><strong>Business Transfers:</strong> In connection with a merger, acquisition, or sale of all or part of our business, your information may be transferred.</li>
      </ul>
      <h3>4. Your Choices and Rights</h3>
      <ul>
        <li><strong>Access and Update:</strong> You can access and update your information by contacting us.</li>
        <li><strong>Opt-Out:</strong> You may opt out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.</li>
        <li><strong>Cookies:</strong> You can control cookies through your browser settings and may refuse to accept cookies.</li>
      </ul>
      <h3>5. Data Security</h3>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee its absolute security.
      </p>
      <h3>6. Children's Privacy</h3>
      <p>
        Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected such information, please contact us so we can take appropriate action.
      </p>
      <h3>7. Changes to This Policy</h3>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website with an updated "Effective Date."
      </p>
      <h3>8. Contact Us</h3>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
      <p><strong>Email:</strong> milesridge.manager@gmail.com</p>
    </section>
  );
}

export default PrivacyPolicy;