import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Footer from './components/Footer';
import Contact from './components/Contact';
import bannerImage from './assets/milesridge banner.png';
import { redirects } from './config';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';

function RedirectHandler() {
  const location = useLocation();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const path = location.pathname.slice(1); // Remove leading slash
    if (path && redirects[path]) {
      setIsRedirecting(true);
      window.location.href = redirects[path];
    } else {
      setIsRedirecting(false);
    }
  }, [location]);

  if (isRedirecting) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h2 style={{ color: 'yellow' }}>redirecting to the App</h2>
      </div>
    );
  }

  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <img src={bannerImage} alt="MilesRidge Banner" className="banner-image" />
        <RedirectHandler />
        <Routes>
          <Route path="/" element={
            <>
              <About />
              <Contact />
            </>
          } />
          <Route path="/ai-img-detection-app" element={null} />
          <Route path="/video-translator" element={null} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;