import React from 'react';
import logo from '../assets/milesridge logo.png';

function Header() {
  return (
    <header>
      <img src={logo} alt="MilesRidge Logo" className="logo" />
      <nav>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;